var $ = require('jquery'),
	slick = require('slick-carousel');


var scrollToSection = function(){
	$('.header_navigation a').on('click', function(e){
		e.preventDefault();

		var sectionName = $(this).attr('href')
		
		$('html, body').animate({
			scrollTop: $(sectionName).offset().top
		}, 800);
	});
};

$(function(){
	$('.feature-list').slick({
	  slidesToShow: 3,
	  slidesToScroll: 1,
	  autoplay: true,
	  autoplaySpeed: 2000,
	  responsive: [
	    {
	      breakpoint: 600,
	      settings: {
	        slidesToShow: 2,
	        slidesToScroll: 2
	      }
	    },
	    {
	      breakpoint: 480,
	      settings: {
	        slidesToShow: 1,
	        slidesToScroll: 1
	      }
	    }
	   ]
	});

	$('.scroll, #home .btn').on('click',function(){
		$('html, body').animate({
			scrollTop: $('.section--services').offset().top
		}, 800);
	});

	if($(window).width() >= 768){
		scrollToSection();
	};

	$(window).on('resize', function(){
		scrollToSection();
	});

	$('.mobile-button').on('click', function(){
		$('.header_navigation ul').toggleClass('active');
	});

	$('.header_navigation a').on('click', function(){
		$('.header_navigation a').removeClass('active');
		
		$(this).addClass('active');
	});
});